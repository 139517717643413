.Root {
    padding: 20px 30px;
    color: var(--color-gray-900);
    width: 100%;
    overflow-x: hidden;
    transition: background-color 0.3s ease;
    display: flex;
    flex-direction: column;
}
.Root:not(:last-child) {
    border-bottom: 1px solid var(--color-gray-250) ;
}

.PostedJustNow {
    transition: background-color 1s ease 1s;
}

.Unread {
    background-color: var(--color-purple-100);
}

.Hovered:hover {
    background-color: #F8F8F8;
    cursor: pointer;
}

.PostButton {
    border-radius: 16px;
    height: 28px;
    padding-right: 15px;
    padding-left: 15px;
}