.Root {
  width: 385px;
  overflow-x: hidden;
  overflow-y: hidden;
  border-left: 1px solid #efefef;
  position: relative;
  height: 100%;
  padding-top: 80px;
  background-color: #ffffff;
}

.Banner {
  position: absolute;
  top: 70px;
  left: 0;
  z-index: 1;
}
