.Strong {
    font-weight: 700;
}

.Centred {
    text-align: center;
    width: 281px;
}

.Centred strong {
    font-weight: 700;
}

.Button:not(.FullWidth) {
    width: 281px;
}

.Button.FullWidth {
    width: 100%;
}

span.Icon {
    margin-top: -30px;
    font-size: 120px;
    margin-bottom: 10px;
}