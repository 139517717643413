.Root {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  height: 54px;
  line-height: 33px;
  white-space: nowrap;
  width: 100%;
  padding-right: 15px;
  transition: all 0.3s ease;
  cursor: pointer;
}

.Root:hover {
  background-color: var(--color-purple-100);
}

.Root.Selected.Disabled {
  cursor: default;
}

.Toggle {
  height: 20px;
  min-width: 20px;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 5px;
  border-radius: 8px;
}

.Toggle:not(:disabled) {
  color: #222;
}

.Toggle:disabled {
  color: #bcbcbc;
}

.ToggleIcon {
  transition: all 0.3s ease;
  transform: rotate(0deg) translateX(1px);
  transform-origin: center center;
}

.Expanded {
  transform: rotate(90deg) translateX(1px);
  transform-origin: center;
}

.TitleWrap {
  display: flex;
  width: 100%;
  height: 54px;
  align-items: center;
  margin-right: 7px;
  min-width: 135px;
}

.BoardInformationContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 38px;
  overflow: hidden;
}

.BoardTitle {
  font-size: 14px;
  line-height: 18px;
  height: 18px;
  color: var(--color-gray-900);
  margin-bottom: 2px;
}

.BoardInfo {
  display: flex;
  align-items: center;
  max-height: 18px;
  font-size: 12px;
  font-weight: 400;
  line-height: normal;
  color: var(--color-gray-700);
  width: 100%;
}

.Icon {
  font-size: 26px;
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.PageBoardTitle {
  line-height: 38px;
  height: 38px;
  margin-bottom: 0;
}

.PageBoardIcon{
  margin-right: 12px;
}

.Title {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
}

.BoardRole {
  font-size: 12px;
  font-weight: 400;
  color: var(--color-gray-500);
}

.SharedContainer {
  color: var(--color-gray-500);
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: normal;
}

.SharedDot {
  width: 11px;
  display: inline-flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 3px;
}

.CountMembers {
  margin-left: 2px;
  font-size: 12px;
  font-weight: 400;
}

.BigTitle {
  font-size: 14px;
}

.Selected,
.Selected:hover {
  background-color: var(--color-purple-200);
  font-weight: 500;
}

.Selected.Inactive,
.Selected.Inactive:hover {
  background-color: var(--color-purple-100);
  font-weight: 500;
}

div.Tag {
  margin-left: 2px;
  padding: 2px 7px;
  height: 18px;
  display: flex;
  align-items: center;
}

.SharedIconGray {
  margin-top: -1px;
  color: var(--color-gray-500);
}
