.Root {
    width: 100%;
    min-width: 300px;
    max-width: 700px;
    border-radius: 14px;

    background: var(--color-gray-200);

    display: flex;
    align-items: center;
}

.Root:not(.WithIndicator) {
    min-height: 90px;
}

.Root.WithIndicator {
    min-height: 105px;
}

.Root:not(.IsDragging):hover {
    background: var(--color-purple-200);
}


.Item {
    width: 100%;
    min-width: 0;
    height: 100%;
    text-align: left;

    flex: 1;
    padding-bottom: 15px;
    min-height: 60px;
}

.DeleteButtonIcon:hover {
    background-color: var(--color-purple-200);
}

.Delete {
    width: 58px;

    display: flex;
    justify-content: center;
}


.DragIcon {
    width: 50px;
    height: 100%;

}

.DeleteIcon {
    vertical-align: middle;
}

.Tooltip {
    margin-top: 15px;
}

.DragIcon svg,
.DeleteIcon path {
    color: var(--color-gray-600);
}

.Root:not(.IsDragging):hover .DragIcon svg {
    color: var(--color-purple-500);
}

.Root:not(.IsDragging):hover .DeleteIcon path {
    color: var(--color-purple-700);
}

.Title {
    font-size: 12px;
    line-height: 14px;
    color: var(--color-gray-600);
    margin: 10px 0 7px 0;
}

.Body {
    min-height: 44px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: center;
}