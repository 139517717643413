.Root {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: var(--color-gray-900);

    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}