.Root {
  width: 100%;
  padding: 30px;
  position: relative;
  transition: all 0.3s ease;
  min-height: 225px;
  border-bottom: 1px solid var(--color-gray-200);
}

div.Title {
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;

  margin-bottom: 20px;
}

span.PlaceholderTitle {
  color: var(--color-gray-400);
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
}

span.PlaceholderBody {
  opacity: 0.3;
  color: var(--color-gray-900);
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
}

div.Body {
  margin-bottom: 30px;
}

.BodyShadow {
  color: #bcbcbc;
}

.Body strong {
  font-weight: 500;
}

.Body {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.Controls {
  display: flex;
  justify-content: right;
}
