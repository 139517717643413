.Text {
  display: block;
  display: -webkit-box;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
  word-break: break-word;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  line-clamp: 3;
}

.After {
  font-size: 14px;
  line-height: 20px;

  font-weight: 400;

  word-break: normal;
  white-space: nowrap;
  color: #888888;
}

.ReadMoreButton {
  border: none;
  color: var(--color-gray-500);
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  position: absolute;
  transform: translateY(100%);
  bottom: -15px;
  left: 0;
}
