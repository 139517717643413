/*// 45px*/
.Updates {
  width: 0;
  min-width: 0;
  position: relative;
  transition:
    width 0.3s ease,
    min-width 0.3s ease;
  overflow: hidden;
}

.Updates:not(.WithNav) {
  height: calc(100% - 80px);
}

.WithNav {
  height: calc(100% - 125px);
}

.UpdatesIsOpened {
  width: 385px;
  min-width: 385px;
}

.UpdatesWithoutAnimation {
  transition: none;
}