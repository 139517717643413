.Label {
    display: block;
    width: 100%;
}

.Input {
    display: block;
    width: 100%;
    border: none;
    border-bottom: 1px solid var(--color-purple-700);
    outline: none;
    background-color: transparent;

    padding: 8px 0;

    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 24px;
}

.Input:-webkit-autofill,
.Input:-webkit-autofill:hover,
.Input:-webkit-autofill:focus,
.Input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.Input:focus,
.Input:active {
    padding-bottom: 7px;
    border-bottom: 2px solid var(--color-purple-700);
}

.Input.Invalid {
    border-bottom-color: #FF0000;
}

.Error {
    color: #FF0000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
}