.Root {
  height: 80px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid var(--color-gray-200);
}

.Header {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
}

.CloseButton {
  position: absolute;
  top: 0;
  bottom: 0;
  margin: auto;
  left: 18px;
  color: var(--color-gray-600);
  height: 38px;
  width: 38px;
}
