.Body {
  flex: 0 0 300px;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-around;
  align-content: space-around;
  height: 100vh;
  width: 100%;
  overflow: hidden;
}

.Root {
  padding-bottom: 100px;
  width: 100%;
}

.Spinner {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}

.Main {
  transition: all 0.5s ease;
  height: 100%;
  flex: 1 1;
  min-width: 30px;
  position: relative;
  overflow: hidden;
}

.Wrap {
  display: flex;
  height: 100%;
  width: 100%;
  overflow: hidden;
  border-top: 1px solid #efefef;
  margin-top: -1px;
}

.Content {
  height: 100%;
  width: 100%;
  overflow: hidden;
}

.Hidden {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: 0;
}

.BulkContainer {
  position: relative;
  z-index: 3;
}

.MobileModal {
  border-radius: 25px;
  overflow: hidden;
}